import React from 'react';
import styled from 'styled-components';
import * as LSTVGlobals from '~/globals';
import FeaturedWedding from '~/components/FeaturedWedding';
import BusinessCards from '~/components/cards/BusinessCards';
import useMainVideo from '~/api/hooks/useMainVideo';
import { couplesNamesFromProperties } from '~/utils/LSTVUtils';
import { EventParams } from '~/globals/trackEvent';

type Props = {
  className?: string;
  hideScrollController?: boolean;
};

const SecondaryDesktopLoggedInHero = ({ className, hideScrollController }: Props) => {
  // TODO: if we want this to be rendered statically, have to get rid of this and have the
  // page do the api call and pass down the video data.
  const video = useMainVideo();

  const { videosSources, post_properties, businesses, title, slug } = video;
  const coupleNames = couplesNamesFromProperties(post_properties);

  if (!videosSources || videosSources.length === 0) return null;

  return (
    <EventParams section="featured_wedding">
      <Container className={className}>
        <FeaturedWedding videoSource={videosSources[0]} title={coupleNames || title} slug={slug} />
        <BusinessCards businesses={businesses} hideScrollController={hideScrollController} />
      </Container>
    </EventParams>
  );
};

const Container = styled.div`
  margin-left: 88px;
  border-top: 1px solid ${LSTVGlobals.MIDGREY};
  display: flex;
  flex-direction: column;
  width: 400px;
`;

export default SecondaryDesktopLoggedInHero;
