import { CloseIcon, SearchIcon } from '~/components/InlineSVG';
import { useEffect, useRef, useState } from 'react';
import BaseCtaButton from '~/components/buttons/BaseCtaButton';
import LocationInput from './LocationInput';
import {
  ButtonContainer,
  ClearIconContainer,
  Container,
  FreeTextContainer,
  IconContainer,
  TextInput,
} from './index.styles';
import { useSearch } from '~/hooks/useSearch';

const FreeTextInput = ({ onFocus, onChange, autoFocus, shouldFocus }: any) => {
  const ref = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const { query } = useSearch();
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(query);
  }, [query]);

  useEffect(() => {
    if (shouldFocus) {
      ref?.current?.focus();
    }
  }, [shouldFocus]);

  const handleFocus = () => {
    setIsFocused(true);
    onFocus();
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    setValue(event?.target?.value);
    onChange(event?.target?.value);
  };

  const handleClear = (event) => {
    event.preventDefault();
    handleChange({ target: { value: '' } });
  };

  return (
    <FreeTextContainer className="free-text-container">
      <IconContainer>
        <SearchIcon fill={isFocused ? 'black' : '#9b9b9b'} />
      </IconContainer>
      <TextInput
        ref={ref}
        autoFocus={autoFocus}
        value={value}
        placeholder="Search For"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <ClearIconContainer $visible={isFocused} onClick={handleClear} onMouseDown={handleClear}>
        <CloseIcon />
      </ClearIconContainer>
    </FreeTextContainer>
  );
};

const SearchInputs = ({
  expendMode,
  autoFocus,
  onFreeTextChange,
  onLocationChange,
  onFreeTextFocus,
  onLocationFocus,
}) => {
  const [expended, setExpended] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const {
    handleSearchButtonClick,
    shouldFocusLocation,
    currentFocusedField,
    locationQuery,
    isResultsOpen,
    selectedLocation,
  } = useSearch();

  useEffect(() => {
    if (expendMode && expended) {
      setExpended(false);
    }
  }, [typeof location !== 'undefined' && location.pathname]);

  useEffect(() => {
    if (selectedLocation || !locationQuery) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [selectedLocation, locationQuery]);

  useEffect(() => {
    if (!isResultsOpen && expendMode && expended && !currentFocusedField) {
      setExpended(false);
    }
  }, [isResultsOpen, currentFocusedField]);

  const handleFreeTextFocus = () => {
    setExpended(true);
    onFreeTextFocus();
  };

  const handleFreeTextChange = (value) => {
    onFreeTextChange(value);
  };

  const handleLocationChange = (value) => {
    onLocationChange(value);
  };

  const onSearch = () => {
    // if (!selectedLocation) {
    //     return
    // }

    handleSearchButtonClick();
  };

  const renderForDevice = (isMobile: boolean) => (
    <>
      <FreeTextInput autoFocus={autoFocus || isMobile} onChange={handleFreeTextChange} onFocus={handleFreeTextFocus} />
      <LocationInput
        hidden={expendMode && !expended}
        onChange={handleLocationChange}
        onFocus={onLocationFocus}
        selectedLocation={selectedLocation}
        shouldFocus={shouldFocusLocation}
        padIcon={!isMobile}
      />
      <ButtonContainer onClick={onSearch}>
        <BaseCtaButton title="Search" hideIcon size="medium" disabled={isButtonDisabled} />
      </ButtonContainer>
    </>
  );

  return (
    <>
      <Container lessThan="md">{renderForDevice(true)}</Container>
      <Container greaterThanOrEqual="md">{renderForDevice(false)}</Container>
    </>
  );
};

export default SearchInputs;
