import styled from 'styled-components';
import * as LSTVGlobals from '~/globals';
import Search, { searchSources } from '~/components/Search';
import { JoinLink } from './JoinLink';
import Typography from '~/atoms/Typography';
import theme from '~/styles/styledComponentsTheme';

const containerBorderRadius = '10px';

const MainLoggedOutHero = () => {
  return (
    <Container>
      <GradientCover>
        <Contents>
          <Typography variant="h1" color={theme.white} center fontWeight="bold">
            Find Your Perfect Wedding Team
          </Typography>
          <Typography variant="h5" center color={theme.white} margin="6px 0 19px 0" fontWeight="600">
            Watch Weddings. Book Pros. Find Fashion.
          </Typography>
          <SearchContainer>
            <Search source={searchSources.home} />
          </SearchContainer>
          <JoinLink />
        </Contents>
      </GradientCover>
    </Container>
  );
};

const SearchContainer = styled.div`
  margin-bottom: 35px;
  width: 740px;
  background: ${LSTVGlobals.ABSOLUTE_WHITE};
  border-radius: 80px;

  @media ${LSTVGlobals.UserDevice.isMobile} {
    width: 304px;
  }

  @media ${LSTVGlobals.UserDevice.isTablet} {
    width: 400px;
  }
`;

const Container = styled.div`
  background-image: url(/images/hero_background_mobile.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${containerBorderRadius};
  overflow: visible;

  @media ${LSTVGlobals.UserDevice.tablet} {
    margin: 33px 46px;
    background-image: url(/images/hero_background_desktop.jpg);
  }

  @media ${LSTVGlobals.UserDevice.isMobile} {
    margin: 16px;
  }
`;

const GradientCover = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) -44.29%,
    rgba(0, 0, 0, 0.56) 3.57%,
    rgba(0, 0, 0, 0.75) 44.19%,
    rgba(0, 0, 0, 0.75) 56.98%
  );
  border-radius: ${containerBorderRadius};
`;

const Contents = styled.div`
  height: 664px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${LSTVGlobals.UserDevice.isMobile} {
    height: 580px;
  }

  @media ${LSTVGlobals.UserDevice.isMobileS} {
    height: 480px;
  }
`;

export default MainLoggedOutHero;
