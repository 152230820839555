import styled from 'styled-components';
import * as LSTVGlobals from '~/globals';

export const ContentsContainer = styled.div<{ $withLeftMarginOnLaptop?: boolean; $withRightMarginOnLaptop?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 40%;

  > h2 {
    @media ${LSTVGlobals.UserDevice.isMobile} {
      text-align: center;
    }
  }

  @media ${LSTVGlobals.UserDevice.isWithinMobile} {
    align-items: center;
  }

  @media ${LSTVGlobals.UserDevice.isWithinTablet} {
    max-width: 80%;
  }

  @media ${LSTVGlobals.UserDevice.laptop} {
    ${({ $withLeftMarginOnLaptop: withLeftMarginOnLaptop }) => (withLeftMarginOnLaptop ? 'margin-left: 77px' : '')}
    ${({ $withRightMarginOnLaptop: withRightMarginOnLaptop }) => (withRightMarginOnLaptop ? 'margin-right: 77px' : '')}
  }
`;

export const Container = styled.div`
  height: 551px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${LSTVGlobals.LIGHTGREY};
  border-radius: 10px;
  overflow: hidden;
  margin: 0 16px;

  @media ${LSTVGlobals.UserDevice.tablet} {
    margin: 0 46px;
  }

  @media ${LSTVGlobals.UserDevice.isMobile} {
    height: 830px;
    flex-direction: column;
  }

  @media ${LSTVGlobals.UserDevice.isTablet} {
    height: 880px;
    flex-direction: column;
  }
`;

export const ContentsBackgroundImage = styled.div<{ $image: string; $flexEndOnLaptop?: boolean }>`
  height: 551px;
  width: 50%;
  display: flex;
  background-image: ${({ $image: image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: 170% 170%;
  background-color: ${LSTVGlobals.HIGHLIGHT_7_0};

  @media ${LSTVGlobals.UserDevice.isMobile} {
    height: 446px;
    border-radius: 10px;
    margin: 0 10px 47px 10px;
  }

  @media ${LSTVGlobals.UserDevice.isWithinTablet} {
    justify-content: center;
    width: 100%;
  }

  @media ${LSTVGlobals.UserDevice.tablet} {
    ${({ $flexEndOnLaptop: flexEndOnLaptop }) => (flexEndOnLaptop ? 'justify-content: flex-end;' : '')};
  }
`;

export const LoginButton = styled.button`
  outline: unset;
  border: unset;
  background: unset;
  color: ${LSTVGlobals.ABSOLUTE_WHITE};
  font-family: Calibre;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin-top: 13px;
`;

export const LoginText = styled.span`
  text-decoration: underline;
  color: ${LSTVGlobals.ABSOLUTE_WHITE};
`;

export const CardStackImage = styled.img<{ $withLeftMarginOnLaptop?: boolean; $withRightMarginOnLaptop?: boolean }>`
  @media ${LSTVGlobals.UserDevice.isMobile} {
    height: 310px;
    margin: 10px 16px 13px 16px;
  }

  @media ${LSTVGlobals.UserDevice.isWithinTablet} {
    margin: 10px 16px 13px 16px;
  }

  @media ${LSTVGlobals.UserDevice.tablet} {
    ${({ $withLeftMarginOnLaptop: withLeftMarginOnLaptop }) => (withLeftMarginOnLaptop ? 'margin-left: 77px;' : '')};
    ${({ $withRightMarginOnLaptop: withRightMarginOnLaptop }) =>
      withRightMarginOnLaptop ? 'margin-right: 77px;' : ''};
    width: 350px;
  }

  @media ${LSTVGlobals.UserDevice.laptop} {
    margin: 10px 16px 13px 16px;
    width: 410px;
  }
`;
