import React from 'react';
import styled from 'styled-components';
import * as LSTVGlobals from '~/globals';
import Search, { searchSources } from '~/components/Search';
import Typography from '~/atoms/Typography';
import theme from '~/styles/styledComponentsTheme';

const MainDesktopLoggedInHero = ({ className }: { className?: string }) => {
  return (
    <Container className={className}>
      <BackgroundImg />
      <ContentsContainer>
        <Contents>
          <Typography variant="h1" color={theme.white} fontWeight="bold" center>
            Let&apos;s find your perfect Wedding Team
          </Typography>
          <Typography variant="h5" center color={theme.white} margin="6px 0 19px 0" fontWeight="600">
            Search over 50,000+ local professionals and watch <br />
            real wedding videos that feature their work.
          </Typography>
          <SearchContainer>
            <Search source={searchSources.home} />
          </SearchContainer>
        </Contents>
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const SearchContainer = styled.div`
  background: ${LSTVGlobals.ABSOLUTE_WHITE};
  border-radius: 80px;
  width: 100%;

  @media ${LSTVGlobals.UserDevice.isTablet} {
    width: 180px;
  }
`;

const ContentsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
`;

const BackgroundImg = styled.div`
  background-color: ${(props) => props.theme.highlight_7_0};
  background-image: url(/images/HeroBanner_X_Mobile.svg);
  background-position: right;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

export default MainDesktopLoggedInHero;
