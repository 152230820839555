import React, { useRef } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '~/hooks/useOnClickOutside';
import { searchSources, useSearch } from '~/hooks/useSearch';
import theme from '~/styles/styledComponentsTheme';
import { useMediaReady } from '~/utils/LSTVUtils';
import SearchInputs from '../SearchInputs';
import SearchResults from '../SearchResults';

const Container = styled.div`
  position: relative;

  * {
    box-sizing: border-box;
  }
`;

type Props = {
  autoFocus?: boolean;
  expendable?: boolean;
  source: searchSources;
};

const SearchContent = ({ autoFocus, expendable, source }: Props) => {
  const [isMobile, ready] = useMediaReady(theme.breakpoints.isMobileOrTablet);
  const {
    isResultsOpen,
    handleFieldFocus,
    currentSearchSource,
    handleSearch,
    fields,
    handleClickOutside,
  } = useSearch();
  const ref = useRef(null);
  const resultsRef = useRef(null);

  useOnClickOutside(ref, resultsRef, () => {
    if (source === currentSearchSource) {
      handleClickOutside();
    }
  });

  const handleFreeTextFocus = () => {
    handleFieldFocus(source, fields.FREETEXT);
  };

  const handleLocationFocus = () => {
    handleFieldFocus(source, fields.LOCATION);
  };

  const handleFreeTextChange = (value) => {
    handleSearch(fields.FREETEXT, value);
  };

  const handleLocationInputChange = (value) => {
    handleSearch(fields.LOCATION, value);
  };

  return (
    <div ref={ref}>
      <Container>
        <SearchInputs
          expendMode={expendable}
          autoFocus={autoFocus}
          onFreeTextChange={handleFreeTextChange}
          onLocationChange={handleLocationInputChange}
          onFreeTextFocus={handleFreeTextFocus}
          onLocationFocus={handleLocationFocus}
        />
        <div ref={resultsRef}>
          {ready && (isMobile || (isResultsOpen && currentSearchSource === source)) ? <SearchResults /> : null}
        </div>
      </Container>
    </div>
  );
};

export default SearchContent;
