import { useEffect, useState } from 'react';
import { useAppDataService } from './useAppDataService';

type MainVideo = {
  videosSources?: any[];
  post_properties?: any;
  businesses?: any[];
  title?: string;
  slug?: string;
};

const useMainVideo = () => {
  // TSFIXME
  const [mainVideoData, setMainVideoData] = useState<MainVideo>({});
  const { getMainVideo } = useAppDataService();

  useEffect(() => {
    getMainVideo().then((data) => {
      data && setMainVideoData(data);
    });
  }, []);

  return mainVideoData;
};

export default useMainVideo;
