import { IBanner } from '@lstv/core/types';
import { StringTMap } from '@lstv/core/types/Generics';
import ICardCollection from '@lstv/core/types/ICardCollection';
import { GetStaticProps } from 'next';
import appDataService from '~/api/services/appDataService';
import publicContentService from '~/api/services/publicContentService';
import HomePageCards from '~/components/HomePageCards';
import HomePageHero from '~/components/HomePageHero';
import WfaFinalistsHero from '~/components/WfaFinalistsHero';
import { VERBOSITY_LEVEL_CARD } from '~/globals';
import { SearchProvider } from '~/hooks/useSearch';
import * as S from '~/styles/pages/index.styles';
import SearchModal from '~/components/modals/SearchModal';
import { useAuthService } from '~/api/hooks/useAuthService';
import WatchLiveTVHero, { WatchWeddingTalkHero } from '~/components/WatchLiveTVHero';

type Props = {
    cardSections: StringTMap<ICardCollection[]>;
    cardSectionsLoggedIn: StringTMap<ICardCollection[]>;
    banner: IBanner | null;
};

const HomePage = ({ cardSections, cardSectionsLoggedIn, banner }: Props) => {
    const { loggedIn } = useAuthService();
    return (
        <S.Container>
            <br />
            {/* <WatchWeddingTalkHero/> */}
            <SearchProvider>
                <HomePageHero banner={banner} />
                <SearchModal />
            </SearchProvider>
            {/*<WfaFinalistsHero />*/}
            {/*<WatchLiveTVHero />*/}
            <HomePageCards data={loggedIn ? cardSectionsLoggedIn : cardSections} />
        </S.Container>
    );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
    // TODO: is/was this call useful or necessary?
    // const { getFrontEndSettings } = useAppDataService();

    // NOTE: this wasn't in use but notice that this fn has this boolean param which sends the
    // user's logged-in state to the API. If we *do* want to use it,
    // we'll need to load these cards on the client where we have auth presently.
    const [{ sections: cardSections }, { sections: cardSectionsLoggedIn }, banner] = await Promise.all([
        appDataService.getHomeCardSections(false),
        appDataService.getHomeCardSections(true),
        appDataService.getActiveBanner(),
    ]);

    // fetch the slug content per section row
    for (const key in cardSections) {
        const section = cardSections[key];

        for (const row of section) {
            // combine the slugs for all cards in this row
            const combinedSlugs = row.content
                .map((card) => {
                    if (row.card_type === 'business') return `/business/${card.slug}`;
                    if (row.card_type === 'vibe') return `/vibe/${card.slug}`;
                    return card.slug;
                })
                .join(',');

            if (combinedSlugs) {
                let slugContents = await publicContentService.getSlugContent({
                    slug: combinedSlugs,
                    context: 'card',
                    verbosity: VERBOSITY_LEVEL_CARD,
                });
                if (!Array.isArray(slugContents)) slugContents = [slugContents];

                // insert the full card data object where before only a slug was to be found.
                row.content.forEach((rowContent, index) => {
                    const content = slugContents.find((content) => content.slug === rowContent.slug);
                    if (!content) throw Error(`Err inserting card slug data: ${rowContent.slug}`);
                    row.content[index] = content;
                });
            } else {
                console.warn('No slug string could be composed from', row.content);
            }
        }
    }

    for (const key in cardSectionsLoggedIn) {
        const section = cardSectionsLoggedIn[key];

        for (const row of section) {
            // combine the slugs for all cards in this row
            const combinedSlugs = row.content
                .map((card) => {
                    if (row.card_type === 'business') return `/business/${card.slug}`;
                    if (row.card_type === 'vibe') return `/vibe/${card.slug}`;
                    return card.slug;
                })
                .join(',');

            if (combinedSlugs) {
                const slugContents = await publicContentService.getSlugContent({
                    slug: combinedSlugs,
                    context: 'card',
                    verbosity: VERBOSITY_LEVEL_CARD,
                });

                // insert the full card data object where before only a slug was to be found.
                row.content.forEach((rowContent, index) => {
                    const content = slugContents.find((content) => content.slug === rowContent.slug);
                    if (!content) throw Error(`Err inserting card slug data: ${rowContent.slug}`);
                    row.content[index] = content;
                });
            } else {
                console.warn('No slug string could be composed from', row.content);
            }
        }
    }

    return {
        props: { cardSections, cardSectionsLoggedIn, banner: banner ?? null },
        revalidate: 60,
    };
};

export default HomePage;
