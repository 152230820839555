import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SearchProvider, searchSources, useSearch } from '~/hooks/useSearch';
import Modal from '~/components/modals/Modal';
import SearchContent from '~/components/Search/SearchContent';
import BaseCtaButton from '~/components/buttons/BaseCtaButton';
import { useRouter } from 'next/router';

const Container = styled.div`
  padding: 32px 0 0 0;
`;

const CloseContainer = styled.div`
  margin: 0 0 32px 0;
  padding: 0 24px;
  max-width: 170px;
`;

const SearchModal = () => {
  const { isSearchModalOpen, closeSearchModal } = useSearch();
  const [currentLocationKey, setCurrentLocationKey] = useState('');
  const router = useRouter();

  useEffect(() => {
    if (currentLocationKey && currentLocationKey !== router.pathname) {
      closeSearchModal();
    }
    setCurrentLocationKey(router.pathname);
  }, [router.pathname]);

  if (!isSearchModalOpen) return null;

  return (
    <Modal
      open
      onClose={closeSearchModal}
      shouldDisableScroll={false}
      showCloseButton={false}
      customStyles={modalStyles}
    >
      <Container>
        <CloseContainer>
          <BaseCtaButton title="Back" size="fullWidthMedium" onClick={closeSearchModal} iconLeft />
        </CloseContainer>
        <SearchProvider>
          <SearchContent source={searchSources.modal} />
        </SearchProvider>
      </Container>
    </Modal>
  );
};

const modalStyles = {
  content: {
    borderRadius: '0',
    padding: 0,
  },
};

export default SearchModal;
