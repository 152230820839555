import React from 'react';
import { IBanner } from '@lstv/core/types';
import styled from 'styled-components';
import { Media } from '~/styles/media';
import BfmBanner from './BfmBanner';
import MainDesktopLoggedInHero from './MainDesktopLoggedInHero';
import SecondaryDesktopLoggedInHero from './SecondaryDesktopLoggedInHero';

interface Props {
  banner?: IBanner;
}
const LoggedInDesktopHero: React.FC<Props> = ({ banner }) => {
  return (
    <>
      <Container greaterThan="sm">
        <StyledMainDesktopLoggedInHero />
        <SecondaryDesktopLoggedInHero />
      </Container>
      <Media greaterThan="sm">
        <BfmBanner banner={banner} />
      </Media>
    </>
  );
};

const StyledMainDesktopLoggedInHero = styled(MainDesktopLoggedInHero)`
  flex-grow: 1;
`;

const Container = styled(Media)`
  display: flex;
  height: 560px;
  margin: 40px 46px 55px 46px;
`;

export default LoggedInDesktopHero;
