import React, { useCallback } from 'react';
import styled from 'styled-components';
import * as LSTVGlobals from '~/globals';
import CardSection from './CardSection';
import ValueProposition from '../ValueProposition';
import { StringTMap } from '@lstv/core/types/Generics';
import ICardCollection from '@lstv/core/types/ICardCollection';
import { useRouter } from 'next/router';
import Navigator from '~/lib/Navigator';
import { useAuthService } from '~/api/hooks/useAuthService';

export const CardSectionsStyle = styled.div`
  position: relative;
  background-color: ${LSTVGlobals.SHOWCASE_SECTIONS_BG_COLOR};
  // background-image: ${LSTVGlobals.DIAGONAL_SUBLE_BACKGROUND};

  @media ${LSTVGlobals.UserDevice.tablet} {
    padding: 31px 0 10px 0;
    border-radius: 0 0 20px 20px;
  }

  @media ${LSTVGlobals.UserDevice.isMobile} {
    background-image: none;
  }

  @media ${LSTVGlobals.UserDevice.laptop} {
    border-radius: 0;
  }
`;

const CardSectionContainerStyle = styled.div<{ $withTopBorder?: boolean }>`
  ${({ $withTopBorder: withTopBorder }) => (withTopBorder ? `border-top: 1px solid ${LSTVGlobals.MIDGREY};` : '')};

  margin: 0 16px;

  @media ${LSTVGlobals.UserDevice.tablet} {
    margin: 0 46px;
    padding: 31px 0 91px 0;
  }
`;

const CardSectionItem = ({ even, data, withTopBorder }) => {
  return (
    <CardSectionContainerStyle id="CardSectionContainerStyle" $withTopBorder={withTopBorder}>
      <CardSection data={data} />
    </CardSectionContainerStyle>
  );
};

const CardSectionsBlock = ({ cardSectionsData, cardSectionsKey, showValuePropsCards }) => {
  const router = useRouter();
  const loginAction = useCallback(() => router.push(Navigator.signIn), []);

  return (
    <>
      {cardSectionsData.map((data, index) => (
        <CardSectionItem
          key={index}
          even={index % 2 === 0}
          data={data}
          withTopBorder={!(index === 0 && cardSectionsKey === '0')}
        />
      ))}
      {showValuePropsCards && <ValueProposition cardSectionsKey={cardSectionsKey} onLoginClick={loginAction} />}
    </>
  );
};

type Props = {
  data: StringTMap<ICardCollection[]>;
};

/**
 * Formerly /components/Content/Cards/CardSections
 */
const HomePageCards = ({ data }: Props) => {
  const { loggedIn } = useAuthService();

  return (
    <CardSectionsStyle id="CardSectionsStyle">
      {Object.entries(data).map(([sectionKey, cardSectionsData], index) => (
        <CardSectionsBlock
          key={index}
          cardSectionsKey={sectionKey}
          cardSectionsData={cardSectionsData}
          showValuePropsCards={false && !loggedIn}
        />
      ))}
    </CardSectionsStyle>
  );
};

export default HomePageCards;
