import React from 'react';
import Image from "next/image";
import { IBanner } from '@lstv/core/types';
import { useAuthService } from '~/api/hooks/useAuthService';
import { EventParams } from '~/globals/trackEvent';
import LoggedInDesktopHero from './LoggedInDesktopHero';
import LoggedInMobileHero from './LoggedInMobileHero';
import LoggedInTabletHero from './LoggedInTabletHero';
import LoggedOutDesktopHero from './LoggedOutDesktopHero';
import LoggedOutMobileHero from './LoggedOutMobileHero';
import styled from 'styled-components';
import { minWidth } from '~/styles/media';
import { DesktopOnly, MobileOnly } from '../HeaderDefault/HeaderBar.styles';

const WeddingsishContainer = styled.div`
  margin: 0 20px 20px 20px;

  @media ${minWidth('sm')} {
    margin: 0 auto;
    max-width: 1350px;
  }

`;

interface Props {
  banner?: IBanner;
}
const HomePageHero: React.FC<Props> = ({ banner }) => {
  const { loggedIn } = useAuthService();

  return (
    <EventParams section="hero">
      <WeddingsishContainer>
        <a href="/weddings-ish">
          <div style={{ borderRadius: 20, overflow: "hidden" }}>
            <MobileOnly>
              <Image
                src="/images/weddingsish-with-jove-mobile.png" alt="Weddingish with Jove"
                width="628" height="325"
              />
            </MobileOnly>
            <DesktopOnly>
              <Image
                src="/images/weddingsish-with-jove-desktop.png" alt="Weddingish with Jove"
                width="1350" height="325"
              />
            </DesktopOnly>
          </div>
        </a>
      </WeddingsishContainer>

      {loggedIn ? (
        <>
          <LoggedInMobileHero banner={banner} />
          <LoggedInTabletHero banner={banner} />
          <LoggedInDesktopHero banner={banner} />
        </>
      ) : (
        <>
          <LoggedOutMobileHero banner={banner} />
          <LoggedOutDesktopHero banner={banner} />
        </>
      )}
    </EventParams>
  );
};

export default HomePageHero;
