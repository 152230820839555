import axios from 'axios';
import {
  ExternalOutlets,
  IBanner,
  ICountResponse,
  IPopup,
  IVideoObject,
  ReviewerStatsObject,
  VideoReviewHistory
} from '@lstv/core/types';
import { IVideo } from '~/components/modals/VideoInfoModal/types';

const ContentAxiosInstance = axios.create({ baseURL: `${process.env.NEXT_PUBLIC_API_URL}/v1` });

ContentAxiosInstance.defaults.withCredentials = true;

const ContentService = {
  /*
         _                  ____        _
        / \   _ __  _ __   |  _ \  __ _| |_ __ _
       / _ \ | '_ \| '_ \  | | | |/ _` | __/ _` |
      / ___ \| |_) | |_) | | |_| | (_| | || (_| |
     /_/   \_\ .__/| .__/  |____/ \__,_|\__\__,_|
             |_|   |_|
    */
  async identCall() {
    const res = await ContentAxiosInstance.get('/');
    return res.data.result;
  },
  async getNavbarContent() {
    const res = await ContentAxiosInstance.get('/navbar');
    return res.data.result;
  },
  async getBusinessTypes() {
    const res = await ContentAxiosInstance.get('/businessRoleTypes');
    return res.data.result;
  },
  async getBusinessCapacityTypes() {
    const res = await ContentAxiosInstance.get('/businessCapacityTypes');
    return res.data.result;
  },
  async getMainVideo() {
    const res = await ContentAxiosInstance.get('/mainVideo');
    return res.data.result;
  },
  async getFrontEndSettings() {
    const res = await ContentAxiosInstance.get('/frontEndSettings');
    return res.data.result;
  },
  async getHomeCardSections(loggedIn) {
    const res = await ContentAxiosInstance.get('/homeCardSections', {
      params: { target: loggedIn ? 'logged_in_home_page' : 'logged_out_home_page' }
    });
    return res.data.result;
  },
  async getActiveBanner(): Promise<IBanner> {
    const res = await ContentAxiosInstance.get('/campaigns/banners/_active');
    return res.data.result;
  },
  async getActivePopup(): Promise<IPopup> {
    const res = await ContentAxiosInstance.get('/campaigns/popups/_active');
    return res.data.result;
  },
  async getNextReviewVideoId(currentVideoId = null, direction = 'forward'): Promise<IVideo> {
    const res = await ContentAxiosInstance.get(`/reviewVideoNextId?currentVideoId=${currentVideoId}&direction=${direction}`);
    return res.data.result.id
  },
  async getVideoReviewHistory(): Promise<VideoReviewHistory> {
    const res = await ContentAxiosInstance.get(`/videoReviewHistory`);
    return res.data.result;
  },
  async getReviewVideo(videoAnchorId, loadVideoDirection): Promise<IVideo> {
    const res = await ContentAxiosInstance.get(`/reviewVideo?videoAnchorId=${videoAnchorId}&loadVideoDirection=${loadVideoDirection}`);
    return res.data.result.video;
  },
  async getReviewerStats(): Promise<ReviewerStatsObject> {
    const res = await ContentAxiosInstance.get('/videoReviewerStats');
    return res.data.result;
  },
  async getExternalOutlets(): Promise<ExternalOutlets[]> {
    const res = await ContentAxiosInstance.get('/videoReviewOutlets');
    return res.data.result?.external_outlets;
  }
};

export default ContentService;
