import styled from 'styled-components';
import Typography from '~/atoms/Typography';
import Search, { searchSources } from '~/components/Search';
import * as LSTVGlobals from '~/globals';
import theme from '~/styles/styledComponentsTheme';

const MainMobileLoggedInHero = () => {
  return (
    <Container>
      <BackgroundImg />
      <ContentsContainer>
        <Contents>
          <Typography variant="h2" marginTop="25px" color={theme.white} fontWeight="bold">
            Find Your Perfect
          </Typography>
          <Typography variant="h2" marginBottom="17px" color={theme.white} fontWeight="bold">
            Wedding Team
          </Typography>
          <SearchContainer>
            <Search source={searchSources.home} />
          </SearchContainer>
        </Contents>
      </ContentsContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 190px;
  position: relative;
  margin-top: 14px;
`;

const SearchContainer = styled.div`
  width: 73%;
  background: ${LSTVGlobals.ABSOLUTE_WHITE};
  border-radius: 80px;
`;

const ContentsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BackgroundImg = styled.div`
  background-color: ${(props) => props.theme.highlight_7_0};
  background-image: url(/images/HeroBanner_X_Mobile.svg);
  background-position: right;
  background-size: auto 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 16px;
  height: 165px;
  width: calc(100vw - 32px);
  border-radius: 10px;
`;

export default MainMobileLoggedInHero;
