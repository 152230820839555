import React from 'react';
import { IBanner } from '@lstv/core/types';
import styled from 'styled-components';
import SecondaryDesktopLoggedInHero from './SecondaryDesktopLoggedInHero';
import MainMobileLoggedInHero from './MainMobileLoggedInHero';
import { Media } from '~/styles/media';
import BfmBanner from './BfmBanner';

interface Props {
  banner?: IBanner;
}
const LoggedInTabletHero: React.FC<Props> = ({ banner }) => {
  return (
    <Container at="sm">
      <MainMobileLoggedInHero />
      <BfmBanner banner={banner} />
      <StyledSecondaryDesktopLoggedInHero />
    </Container>
  );
};

const Container = styled(Media)`
  display: flex;
  flex-direction: column;
`;

const StyledSecondaryDesktopLoggedInHero = styled(SecondaryDesktopLoggedInHero)`
  width: unset;
  margin: 0 51px;
`;

export default LoggedInTabletHero;
