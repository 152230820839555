import styled from 'styled-components';
import * as LSTVGlobals from '~/globals';
import FeaturedWedding from '~/components/FeaturedWedding';
import BusinessCards from '~/components/cards/BusinessCards';
import useMainVideo from '~/api/hooks/useMainVideo';
import { couplesNamesFromProperties } from '~/utils/LSTVUtils';
import { EventParams } from '~/globals/trackEvent';

const SecondaryMobileLoggedInHero = ({
  className,
  hideScrollController,
}: {
  className?: string;
  hideScrollController?: boolean;
}) => {
  const video = useMainVideo();
  const { videosSources, post_properties, businesses, title, slug } = video;
  const coupleNames = couplesNamesFromProperties(post_properties);
  return videosSources && videosSources?.length > 0 ? (
    <EventParams section="featured_wedding">
      <Container className={className}>
        <FeaturedWedding videoSource={videosSources[0]} title={coupleNames || title} slug={slug} />
        <BusinessCards hideScrollController={!!hideScrollController} businesses={businesses} />
      </Container>
    </EventParams>
  ) : null;
};

const Container = styled.div`
  margin: 0 60px 0 88px;
  border-top: 1px solid ${LSTVGlobals.MIDGREY};
  display: flex;
  flex-direction: column;
`;

export default SecondaryMobileLoggedInHero;
