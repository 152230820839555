import { useSearch, searchSources } from '~/hooks/useSearch';
import SearchPanel from './SearchPanel';

interface Props {
  expendable?: boolean;
  source: searchSources;
}

const Search = ({ expendable, source }: Props) => {
  const { openSearchModal } = useSearch();

  // AK: don't believe we need expendable anymore
  return <SearchPanel onOpen={openSearchModal} expendable={expendable} source={source} />;
};

export { searchSources };
export default Search;
