import React from 'react';
import { IBanner } from '@lstv/core/types';
import { Media } from '~/styles/media';
import BfmBanner from './BfmBanner';
import MainLoggedOutHero from './MainLoggedOutHero';

interface Props {
  banner?: IBanner;
}
const LoggedOutMobileHero: React.FC<Props> = ({ banner }) => {
  return (
    <Media at="xs">
      <MainLoggedOutHero />
      <BfmBanner banner={banner} />
    </Media>
  );
};

export default LoggedOutMobileHero;
