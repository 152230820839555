import { IBanner } from '@lstv/core/types';
import styled from 'styled-components';
import { Media, breakpoints } from '~/styles/media';
import React from 'react';
import { popMessageError, popMessageSuccess } from '~/utils/LSTVUtils';
import campaignService from '~/api/services/campaignService';
import theme from '~/styles/styledComponentsTheme';

const Container = styled.div<{ $backgroundColor: string; $textColor: string }>`
  background-color: ${(props) => props.$backgroundColor};
  color: ${(props) => props.$textColor};
  margin: 33px 46px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media ${theme.breakpoints.tablet} {
    flex-direction: row-reverse;
  }

  @media ${theme.breakpoints.desktop} {
    padding: 30px;
  }
`;

const ImageContainer = styled.div`
  @media ${theme.breakpoints.tablet} {
    max-width: 50%;
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media ${theme.breakpoints.tablet} {
    text-align: left;
    justify-content: center;
    padding: 0 27px;
  }

  @media ${theme.breakpoints.desktop} {
    padding: 0 56px;
  }
`;

const HeadlineContainer = styled.div`
  padding: 24px 0;
  font-size: 34px;

  @media ${theme.breakpoints.desktop} {
    font-size: 40px;
  }
`;

const BodyContainer = styled.div`
  text-align: center;
  padding: 12px 0;
  font-size: 18px;

  @media ${theme.breakpoints.tablet} {
    text-align: left;
    font-size: 21px;
  }
`;

const FormContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;

  @media ${theme.breakpoints.tablet} {
    align-items: start;
    align-self: start;
  }
`;

const CtaContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column-reverse;
  align-self: center;
  align-items: center;

  @media ${theme.breakpoints.tablet} {
    flex-direction: row;
    align-items: start;
    align-self: start;
  }
`;

const EmailInput = styled.input`
  line-height: 54px;
  font-size: 20px;
  padding: 0 16px;
  border-radius: 10px;
  width: 300px;
  margin-bottom: 25px;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${(props) => props.theme.coldGrey};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${(props) => props.theme.coldGrey};
  }
`;

const CtaLink = styled.a<{ $color: string }>`
  background: transparent;
  width: 150px;
  color: ${(props) => props.color};
  padding: 14px 0;
`;

const CtaButton = styled.a<{ $color: string }>`
  background: transparent;
  text-decoration: none;
  text-align: center;
  border: 1px solid ${(props) => props.color};
  border-radius: 8px;
  padding: 14px 0;
  width: 200px;
  color: ${(props) => props.color};

  :hover {
    color: inherit;
  }
`;

const BannerBody: React.FC<{ banner: IBanner }> = ({ banner }) => {
  const [email, setEmail] = React.useState<string>('');

  const signUp = async (email: string) => {
    const success = await campaignService.subscribeWithPop(email, banner.klaviyo_list_id || 'VM7QpN');
    if (success) {
      setEmail('');
    }
  };

  return (
    <TextContainer>
      <HeadlineContainer>{banner.headline}</HeadlineContainer>
      <BodyContainer>{banner.body}</BodyContainer>
      {banner.ask_for_email ? (
        <FormContainer>
          <EmailInput
            value={email}
            onChange={({ currentTarget: { value } }) => setEmail(value)}
            placeholder="Enter Your Email"
          />
          <CtaButton $color={banner.text_color} onClick={() => signUp(email.trim())}>
            Sign Up
          </CtaButton>
        </FormContainer>
      ) : (
        <CtaContainer>
          {banner.cta_1_text && banner.cta_1_url && (
            <CtaLink $color={banner.text_color} href={banner.cta_1_url}>
              {banner.cta_1_text}
            </CtaLink>
          )}
          {banner.cta_2_text && banner.cta_2_url && (
            <CtaButton $color={banner.text_color} href={banner.cta_2_url}>
              {banner.cta_2_text}
            </CtaButton>
          )}
        </CtaContainer>
      )}
    </TextContainer>
  );
};

interface Props {
  banner?: IBanner;
}
const BfmBanner: React.FC<Props> = ({ banner }) => {
  if (!banner) return null;

  return (
    <Container $backgroundColor={banner.background_color} $textColor={banner.text_color}>
      <ImageContainer>
        <Media at="sm">
          <img src={banner.tablet_image_url} alt="banner image" />
        </Media>
        <Media lessThan="sm">
          <img src={banner.desktop_image_url} alt="banner image" style={{ width: '100%' }} />
        </Media>
        <Media greaterThan="sm">
          <img src={banner.desktop_image_url} alt="banner image" />
        </Media>
      </ImageContainer>
      <BannerBody banner={banner} />
    </Container>
  );
};

export default BfmBanner;
