import React from 'react';
import { IBanner } from '@lstv/core/types';
import styled from 'styled-components';
import { Media } from '~/styles/media';
import BfmBanner from './BfmBanner';
import MainMobileLoggedInHero from './MainMobileLoggedInHero';
import SecondaryMobileLoggedInHero from './SecondaryMobileLoggedInHero';

interface Props {
  banner?: IBanner;
}
const LoggedInMobileHero: React.FC<Props> = ({ banner }) => {
  return (
    <Media at="xs">
      <MainMobileLoggedInHero />
      <BfmBanner banner={banner} />
      <StyledSecondaryMobileLoggedInHero hideScrollController />
    </Media>
  );
};

const StyledSecondaryMobileLoggedInHero = styled(SecondaryMobileLoggedInHero)`
  width: 90%;
  margin: 16px;
  width: unset;
`;

export default LoggedInMobileHero;
