import styled from 'styled-components';
import Typography from '~/atoms/Typography';
import LSTVLink from '~/components/LSTVLink';
import VideoPlayer from '~/components/VideoPlayer';

const FeaturedWedding = ({ videoSource, title, slug }) => {
  if (!videoSource) return null;
  return (
    <FeaturedWeddingContainer>
      <Typography variant="h2" marginBottom="17px" fontWeight="bold">
        Featured Wedding
      </Typography>
      <VideoPlayer
        isAutoPlay={false}
        onPercentageComplete={null}
        onVideoComplete={null}
        video={videoSource}
        upNextSlide={null}
      />
      <LSTVLink styled={false} to={slug}>
        <Typography variant="h5" marginTop="10px" fontWeight="600">
          {title}
        </Typography>
      </LSTVLink>
    </FeaturedWeddingContainer>
  );
};

const FeaturedWeddingContainer = styled.div`
  flex: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 27px;
`;

export default FeaturedWedding;
