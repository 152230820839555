import BaseCTAButton from '~/components/buttons/BaseCtaButton';
import Navigator from '~/lib/Navigator';
import LSTVLink from '../LSTVLink';
import * as LSTVGlobals from '~/globals';
import * as S from './index.styles';
import { EventParams } from '~/globals/trackEvent';
import { Media } from '~/styles/media';
import Typography from '~/atoms/Typography';

type ContentsProps = {
  headerText: string;
  subHeaderText: string;
  onLoginClick: () => void;
  withRightMarginOnLaptop?: boolean;
  withLeftMarginOnLaptop?: boolean;
};

const Contents = ({
  headerText,
  subHeaderText,
  onLoginClick,
  withRightMarginOnLaptop,
  withLeftMarginOnLaptop,
}: ContentsProps) => {
  return (
    <S.ContentsContainer
      $withRightMarginOnLaptop={withRightMarginOnLaptop}
      $withLeftMarginOnLaptop={withLeftMarginOnLaptop}
    >
      <Typography
        variant="h1"
        color={LSTVGlobals.ABSOLUTE_WHITE}
        marginBottom="22px"
        fontWeight="800"
        customStyles="max-width: 80%;"
      >
        {headerText}
      </Typography>
      <Typography variant="h5" color={LSTVGlobals.ABSOLUTE_WHITE} marginBottom="22px" customStyles="max-width: 90%;">
        {subHeaderText}
      </Typography>
      <LSTVLink to={Navigator.signIn} styled={false}>
        <BaseCTAButton title="Get Started" size="large" lightMode />
      </LSTVLink>
      <S.LoginButton onClick={onLoginClick}>
        Already a member? <S.LoginText>Log In</S.LoginText>
      </S.LoginButton>
    </S.ContentsContainer>
  );
};

const ValueProposition = ({
  cardSectionsKey,
  onLoginClick,
}: {
  cardSectionsKey: '0' | '1' | '2';
  onLoginClick: () => void;
}) => {
  let prop;

  switch (cardSectionsKey) {
    case '0':
      prop = (
        <EventParams section="value_proposition" section_title="Real Wedding Videos">
          <S.Container>
            <S.ContentsBackgroundImage $image="/images/os.svg">
              <Contents
                headerText={`Real Wedding Videos`}
                subHeaderText="Watch weddings by venue, location, style, religion, culture, and more to get ideas and find pros for
                      your wedding."
                onLoginClick={onLoginClick}
                withLeftMarginOnLaptop
              />
            </S.ContentsBackgroundImage>
            <S.CardStackImage $withRightMarginOnLaptop src="/images/Videos.png" />
          </S.Container>
        </EventParams>
      );
      break;
    case '1':
      prop = (
        <EventParams section="value_proposition" section_title="Find Your Wedding Team">
          <S.Container>
            <Media greaterThanOrEqual="md">
              {(className) => <S.CardStackImage {...{ className }} $withLeftMarginOnLaptop src="/images/Vendors.png" />}
            </Media>
            <S.ContentsBackgroundImage $flexEndOnLaptop $image="/images/xs.svg">
              <Contents
                headerText="Find Your Wedding Team"
                subHeaderText="Search and browse tens of thousands of wedding pros to find the right vendors for your wedding."
                onLoginClick={onLoginClick}
                withRightMarginOnLaptop
              />
            </S.ContentsBackgroundImage>
            <Media lessThan="md">
              {(className) => <S.CardStackImage {...{ className }} $withLeftMarginOnLaptop src="/images/Vendors.png" />}
            </Media>
          </S.Container>
        </EventParams>
      );
      break;
    case '2':
      prop = (
        <EventParams section="value_proposition" section_title="Ideas And Tips">
          <S.Container>
            <S.ContentsBackgroundImage $image="/images/os.svg">
              <Contents
                headerText="Ideas And Tips"
                subHeaderText="Get inspired with our editors' favorite weddings and advice from experts"
                onLoginClick={onLoginClick}
                withLeftMarginOnLaptop
              />
            </S.ContentsBackgroundImage>
            <S.CardStackImage $withRightMarginOnLaptop src="/images/Article.png" />
          </S.Container>
        </EventParams>
      );
      break;
  }

  return prop;
};

export default ValueProposition;
